<template>
  <div>
    <b-container class="h-100 py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="" v-if="!token">
                <h1 class="h2 mb-5 font-weight-bold text-center d-flex align-items-center justify-content-center">
                  <comline-logo :fill="'#212529'" :height="'40px'" :width="'40px'" class="mb-0 mr-2 d-flex align-items-center" />
                  ACS-Dashboard
                </h1>
                <div v-if="error" class="alert alert-danger">{{ errorMessage }}</div>
                <p>Bitte melde dich über das ComLine Prtal an.</p>
                <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="openLogIn()" class="bt btn-primary">Portal</b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { auth } from "../plugins/firebase"
import { signInWithCustomToken } from "firebase/auth";

export default {
  name: "SignInView",
  data() {
    return {
      errorMessage: "",
      states: {
        email: null,
      },
    };
  },
  computed: {
    showAlert() {
      return this.errorMessage !== "";
    },
  },
  mounted(){
    const token = this.$route.params.token;
    this.token = token;

    if(token){
      signInWithCustomToken(auth, token)
        .then(() => this.$router.replace({ name: "HistoryView" }))
        .catch((error) => {
          console.error(error)
          this.$router.replace({ name: "SignInView" })
        })
    }
  }, 
  methods: {
    async openLogIn() {
      window.location.href='https://portal.comline.app';
    }
  }
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
