<template>
  <div class="d-flex justify-content-center align-items-center bg-white my-5" style="height: 100vh;" v-if="isLoading">
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>

  <b-card no-body v-else class="rounded-0">
    <b-card-header class="p-3 bg-light d-flex justify-content-between">
      <b-input-group style="width: 400px;">
        <template #prepend>
          <b-input-group-text class="bg-white pr-0">
            <fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon>
          </b-input-group-text>
        </template>
        <template #append>
          <b-button variant="dark" v-on:click="clearFilter()"><fa-icon :icon="['fa-regular', 'fa-xmark']"></fa-icon></b-button>
        </template>
        <b-input placeholder="Suche" class="border-left-0" v-model="filter" debounce="600"></b-input>
      </b-input-group>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        class="mb-0"
      ></b-pagination>
    </b-card-header>
    <b-table
      :items="resellers"
      :fields="fields"
      class="mb-0"
      :filter="$store.state.resellerFilter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      :per-page="perPage"
      :current-page="currentPage"
      :no-provider-paging="true"
      :no-provider-sorting="true"
      :no-provider-filtering="true"
      @filtered="onFiltered"
      >
      <template #cell(companyName)="data">
        <b-link :to="{ name: 'ResellerView', params: { resellerId: data.item.resellerId } }">{{ data.item.companyName}}</b-link>
      </template>
      <template #cell(resellerId)="data">
        {{ data.item.resellerId }}
      </template>
      <template #cell(customernumber)="data">
        {{ data.item.customernumber }}
      </template>
      <template #cell(status)="data">
        <fa-icon
          :icon="['fa-regular', statusIcon(data.item.status )]"
          :class="`mr-1 ${statusClass(data.item.status )}`"
        ></fa-icon>
        {{ data.item.status }}
        {{ data.item.status | accountStatus }}
      </template>
      <template #cell(creationDate)="data">
        {{ data.item.creationDate | dateTime }}
      </template>
      <template #cell(actions)="data">
        <div class="text-right">
          <b-dropdown id="dropdown-dropleft" toggle-class="text-decoration-none" dropleft variant="link" no-caret>
            <template #button-content>
              <fa-icon :icon="['fa-regular', 'ellipsis-vertical']" size="xl" color="darkgrey"></fa-icon>
            </template>
            <b-dropdown-item :to="{ name: 'MassTransferView', params: { resellerId: data.item.resellerId } }">Massentransfer</b-dropdown-item>
            <b-dropdown-item v-show="paymentInAdvanceCustomers.includes(data.item.resellerId)" @click="removeFromPaymentInAdvanceCustomers(data.item.resellerId)" v-b-tooltip.hover :title="'Ist AN'"><fa-icon :icon="['fa-regular', 'circle-check']" size="1x" color="green"/> Vorkassen-E-Mail</b-dropdown-item>
            <b-dropdown-item v-show="!paymentInAdvanceCustomers.includes(data.item.resellerId)" @click="addToPaymentInAdvanceCustomers(data.item.resellerId)" v-b-tooltip.hover :title="'Ist AUS'"><fa-icon :icon="['fa-regular', 'circle-xmark']" size="1x" color="red"/> Vorkassen-E-Mail</b-dropdown-item>
            <b-dropdown-item @click="logInAsReseller(data.item.customernumber)"><fa-icon :icon="['fa-regular', 'right-to-bracket']" size="1x" class="mr-1"/>Log-In</b-dropdown-item>
          </b-dropdown>
        </div>

          <!-- <b-button variant="dark" :to="{ name: 'MassTransferView', params: { resellerId: data.item.resellerId} }" >Massentransfer</b-button> -->
      </template>
    </b-table>
    <template #footer>
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column align-items-center text-muted">
          {{ rows }} Reseller
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import settings from "../settings";
import { firestore, functions} from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { collection, getDocs, updateDoc, getDoc, doc, getFirestore, arrayRemove, arrayUnion } from "firebase/firestore"; 

export default {
  name: "ResellersView",
  data() {
    return {
      rows: 0,
      perPage: 25,
      currentPage: 1,
      paymentInAdvanceCustomers: [],
      filter: "",
      isLoading: true,
      resellers: [],
      sortBy: "creationDate",
      sortDesc: true,
      fields: [
        {
          key: "companyName",
          label: "Reseller",
          sortable: true,
        },
        {
          key: "customernumber",
          label: "Kundennummer",
          sortable: true,
        },
        {
          key: "resellerId",
          label: "Reseller-ID",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "creationDate",
          label: "Creation-Date",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.q) {
      this.filter = this.$route.query.q || this.$store.state.resellerFilter;
      this.$store.state.resellerFilter = this.filter;
    } else {
      this.filter = this.$store.state.resellerFilter;
    }

    this.getResellers();
  },
  computed: {
    tableHeight() {
      return window.innerHeight - 64 - 71 - 51 + "px";
    },
  },
  watch: {
    filter(filter) {
      this.$store.commit("resellerFilter", filter);
    },
  },
  methods: {
    async getResellers() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "resellers"));
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          this.resellers.push({
            "customernumber": data.customernumber,
            "companyName": data.companyProfile.companyName,
            "creationDate": data.creationDate,
            "resellerId": data.resellerId,
            "status": data.status,
          });
        });
        this.rows = this.resellers.length;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getPaymentInAdvanceCustomers() {
      const doc = await getDoc(doc(getFirestore(), "dashboardSettings", "paymentInAdvanceCustomers"));
      if (doc.exists()) {
        this.paymentInAdvanceCustomers = doc.data()?.paymentInAdvanceCustomers;
      } else {
        this.paymentInAdvanceCustomers = [];
      }
    },
    statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    clearFilter() {
      this.filter = "";
    },
    removeFromPaymentInAdvanceCustomers(resellerId) {
      this.paymentInAdvanceCustomers = this.paymentInAdvanceCustomers.filter(function(e) { return e !== resellerId })
      updateDoc(doc(getFirestore(), "dashboardSettings", "paymentInAdvanceCustomers"), {paymentInAdvanceCustomers: arrayRemove(resellerId)})
      .catch((err)=>{
        console.error(err)
      })
    },
    addToPaymentInAdvanceCustomers(resellerId) {
      this.paymentInAdvanceCustomers.push(resellerId);
      this.paymentInAdvanceCustomers = [...new Set(this.paymentInAdvanceCustomers)];
    
      updateDoc(doc(getFirestore(), "dashboardSettings", "paymentInAdvanceCustomers"), {paymentInAdvanceCustomers: arrayUnion(resellerId)})
      .catch((err)=>{
        console.error(err)
      })
    },
    logInAsReseller(value) {
      const newWindow = this.openWindow('', value)
      const signInAdminAsReseller = httpsCallable(functions, "signInAdminAsReseller");
      signInAdminAsReseller({
        uid: value,
      })
        .then((data) => {
          console.log(data.data);
          newWindow.location.href = `${settings.storeUrl()}/log-in/${data.data}`
        })
    },
    openWindow (url, name) {
      const newWindow = window.open(url, name)  
      if (!newWindow) {
        alert('Please unblock popups in your browser settings.')
      } else if (window.focus) {
        newWindow.focus()
      }
      return newWindow
    },
    onFiltered(filteredItems) {
      console.log(filteredItems.length);
      this.rows = filteredItems.length
    }
  },
};
</script>
