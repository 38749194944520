<template>
  <b-button variant="dark" v-on:click="exportData()" class="nowrap">Daten&nbsp;exportieren</b-button>
</template>

<script>
import { utils, writeFileXLSX } from 'xlsx';
// import moment from 'moment-timezone';

export default {
  name: "ExportCustomerComponent",
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  methods: {
    exportData() {
      const exportItems = this.items.map(element => {
        let customer =  {
          "companyName": element.companyName,
          "customerId": element.allData.customerId,
          "cotermDate": element.allData.cotermDate,
          "resellerId": element.allData.resellerId,
          "externalReferenceId": element.allData.externalReferenceId,
          "status": element.allData.status,
          "linkedMembership": element.allData.linkedMembership,
          "marketSegment": element.marketSegment,
          "creationDate": element.allData.creationDate,
        }
        
        return customer

      });
      const worksheet = utils.json_to_sheet(exportItems);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Daten");
      writeFileXLSX(workbook, "acs-dashboard-export.xlsx");
    }
  }
}
</script>